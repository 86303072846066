import React from "react"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import {useStaticQuery, graphql} from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import Stappenplan from "./stappenplan-inkoop.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Img from "gatsby-image"
import { withPrefix } from "gatsby"
import Headerimage from "../../components/headerimage"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);

    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`


const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;

    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }

`
const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        padding-bottom: 60px;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`
const Imagecontainer = styled.div`
    padding: 0 70px;
    box-sizing: border-box;
    div{
        border: 1px solid rgba(0,0,0,0.4);

    }
`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;

    @media ${device.tablet}{
        grid-template-columns: 100%;
    }

`

const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }



`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}



const SecondPage = () => (
  <Layout>
    <SEO title="Inkoop" />
    <PageHeader title="Inkoop"  bannerimage={<Headerimage something="1"/> }>
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/diensten" onClick={handleClick}>
            Diensten
          </Crumblink>
          <Crumblink aria-current="page" href="${}/diensten/inkoop/" onClick={handleClick}>
            Inkoop
          </Crumblink>
        </Styledcrumbs>        

    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Subheader>Bent u benieuwd hoeveel uw oude hardware nog waard is?</Subheader>
                    <Paragraph>
                    Heeft uw bedrijf een partij overtollige smartphones en/of ICT apparatuur staan of is uw apparatuur toe aan vervanging?<br/>Door middel van hergebruik en recycling kunt u er voor zorgen dat de levensduur van ICT apparatuur en materialen wordt verlengd.<br/>Green Digital kan gebruikte ICT apparatuur tegen zeer interessante prijzen van u overnemen.<br/>Wij verzorgen snel een vrijblijvende waardebepaling voor u.
                    </Paragraph>

                </Vertpad>
                <Vertpad>
                    <Flexdivider>
                        <div>
                            <Subheader>Welke apparatuur kopen wij in?</Subheader>
                            <Paragraph>
                            Green Digital koopt al uw ICT apparatuur in, dus ook de oude, minder courante en defecte apparaten. Zo bent u verzekerd dat alle apparatuur wordt afgenomen en u niet nogmaals zelf apparatuur moet afvoeren. Ook kunnen wij in overleg bekijken of wij het bijbehorende kantoormeubilair kunnen inkopen. Voor volledige ontruiming van uw kantoor- inclusief ICT apparatuur kunnen wij een aanbieding en plan van aanpak verzorgen. 
                            </Paragraph>
                        </div>
                        <Imagecontainer>
                            <Headerimage something="2" />
                        </Imagecontainer>
                    </Flexdivider>
                </Vertpad>
                <Vertpad>
                <Subheader>Wat zijn de voordelen van de opkoop door Green Digital?</Subheader>
                    <Checkgrid>
                        <Griditem><Icoon icon={faCheck}/><p><b>Milieuvriendelijk</b><br/>Door te kiezen voor hergebruik van IT hardware, draagt u bij aan het tegengaan van verspilling van waardevolle grondstoffen en de reductie van CO2.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Snelle waardebepaling</b> <br/>Binnen 48 uur ontvangt u van ons een aanbieding</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Hoge Restwaarde</b> <br/>Beste prijs door een breed netwerk aan business-partners</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>100% veilig</b> <br/>Als bewijs van  succesvolle vernietiging ontvangt u van alle datadragers een officieel datavernietigingscertificaat</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Kosteloze afvoer</b> <br/>Wij verzorgen de logistiek én afvoer van de apparatuur</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Conform wettelijke voorschriften</b> <br/>Wij zijn in het bezit van de verplichte vergunningen voor afvoer en verwerking van elektronische apparatuur</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>AVG/GDPR compliant</b> <br/>100% veilige dataverwijdering en vernietiging datadragers</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Social Return</b> <br/>Wij bieden de mogelijkheid om de restwaarde te doneren aan een goed doel naar keuze</p></Griditem>
                    </Checkgrid>
                </Vertpad>
                <Vertpad>
                    <Flexdivider></Flexdivider>
                    <Subheader>Hardware opkopen, hoe werkt dat?</Subheader>
                    <Paragraph>
                    Eigenlijk heel eenvoudig. In een paar simpele stappen helpen we u van uw oude hardware af en ontvangt u een mooi bedrag voor de restwaarde.
                    </Paragraph>
                    <Stappen2 stappenplan={Stappenplan} />
                </Vertpad>

            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout> 
)

export default SecondPage


